export const round = (number, digits = 3) => {
  return Math.round(number * Math.pow(10, digits)) / Math.pow(10, digits);
};

const cent_to_unit = (number) => {
  return number / 100;
};

export const prettyNumber = (number, type) => {
  const numberConfig = { minimumFractionDigits: 2 };

  const percentConfig = { minimumFractionDigits: 2 };
  switch (type) {
    case "currency":
      return `R$ ${round(number, 2).toLocaleString("pt-BR", numberConfig)}`;

    case "cents":
      return `R$ ${cent_to_unit(number).toLocaleString("pt-BR", numberConfig)}`;

    case "percent":
      return `${round(number * 100, 2).toLocaleString(
        "pt-BR",
        percentConfig
      )}%`;

    case "float":
      return `${round(number, 2).toLocaleString("pt-BR", numberConfig)}`;

    case "integer":
      return `${round(number, 0).toLocaleString("pt-BR")}`;
    case "asIs":
      return `${number}`;
    default:
      return `${number}`;
  }
};
