import { Box, Typography, useTheme } from "@mui/material";
import Header from "../../global/layoutComponents/Header";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colorTokens } from "../../theme";

const FaqQuestion = ({ question, answer }) => {
  const theme = useTheme();
  const colors = colorTokens(theme.palette.mode);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon sx={{ color: theme.palette.neutral.dark }} />
        }
        sx={{
          backgroundColor: theme.palette.neutral.light,
        }}
      >
        <Typography
          color={theme.palette.neutral.dark}
          variant="h5"
          fontWeight="bold"
        >
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: colors.grey[100],
          borderBlockColor: theme.palette.background,
        }}
      >
        <Typography color={theme.palette.neutral.dark} variant="h6">
          {answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const Faq = () => {
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Dúvidas frequentes" />
      <FaqQuestion
        question="Uma pergunta interessante?"
        answer="Uma resposta mais interessante"
      />
      <FaqQuestion
        question="Uma pergunta interessante2?"
        answer="Uma resposta mais interessante2"
      />
    </Box>
  );
};

export default Faq;
